.botao{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 1rem 1.5rem;
    border: 2px solid transparent;
    border-radius: 2rem;
    font-size: 1rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.botao svg{
    font-size: 1em;
}

.botao span{
    font-size: 1em;
    font-weight: 600;
}

/* CORES */

.primary{
    color: var(--site_textCP);
    background-color: var(--site_primaryM);
    transition: all 0.3s;
}
.primary:hover{
    color: var(--site_primaryM);
    background-color: var(--site_secondaryM);
}

.secondary{
    color: var(--site_textCSe);
    background-color: var(--site_secondaryM);
    transition: all 0.3s;
}
.secondary:hover{
    color: var(--site_secondaryM);
    background-color: var(--site_primaryM);
}