.container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 12rem 4rem 6rem 4rem;
    color: var(--site_textCSe);
    background-color: var(--site_secondaryM);
}

.container::before {
    content: '';
    position: absolute;
    top: -1px;
    width: 100%;
    height: 6rem;
    background-color: var(--site_bgP);
    border-radius: 0 0 10rem 10rem;
}

.links-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.column-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
}

.logo-footer {
    width: 100%;
}

.logo-text {
    text-align: justify;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 1rem;
}

.socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
}

.socials a {
    display: flex;
    flex-direction: row;
    width: fit-content;
    border: none;
    margin-right: 1rem;
    font-size: 3rem;
    transition: all 0.3s;
}

.socials a:hover {
    color: var(--site_primaryM);
}

.title-column {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--site_textCSe);
    margin-bottom: 1rem;
}

.link-footer {
    width: fit-content;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 1rem;
    transition: all 0.3s;
}

.link-footer:hover {
    color: var(--site_primaryM);
}

.copyright {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 2rem;
    border-top: 1px solid var(--divider);
    margin-top: 2rem;
}

.text-copyright {
    font-size: 1rem;
    font-weight: 300;
}

@media (max-width: 768px) {
    .container {
        padding: 12rem 2rem 6rem 2rem;
    }

    .links-footer {
        flex-direction: column;
    }

    .column-footer {
        align-items: center;
        padding: 1rem 0rem;
    }
}