.container{
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding: 2rem;
    border-radius: 0.5rem;
    color: var(--site_textCP);
    background-color: var(--site_primaryM);
    box-shadow: var(--shadow3);
    transition: all 0.3s;
}

.header{
    display: flex;
    flex-direction: column;
}

.chip-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    font-size: 2rem;
    color: var(--site_textCSe);
    background-color: var(--site_secondaryM);
    transition: all 0.3s;
}

.empresa-chip{
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
}

.nome-plano{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0.5rem;
}

.lista-beneficios{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 0;
}

.beneficio{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
}
.beneficio:last-child{
    margin-bottom: 0rem;
}

.beneficio svg{
    font-size: 1.3rem;
    min-width: 1.3rem;
    margin-right: 1rem;
    color: var(--site_secondaryM);
    transition: all 0.3s;
}

.beneficio span{
    font-size: 1.1rem;
    font-weight: 400;
}

.valor-plano{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.txt-por{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.valor{
    font-size: 2rem;
    font-weight: 600;
}

.container a{
    border: 2px solid var(--site_secondaryM);
    box-shadow: none;
}

/* HOVER */

.container:hover{
    color: var(--site_textCSe);
    background-color: var(--site_secondaryM);
}

.container:hover .chip-icon{
    color: var(--site_secondaryM);
    background-color: var(--site_primaryM);
}

.container:hover .beneficio svg{
    color: var(--site_textCSe);
}

.container:hover a{
    border: 2px solid transparent;
}

@media (max-width: 768px) {
    .container{
        width: 95%;
    }
}